import React from 'react'
import Head from './components/Head'
import Footer from './components/Footer'

const Aboutus = () => {
    return (
        <div className=''>
            <Head />
            <h2 class="mb-2 mt-2 text-center  " style={{ color: "#FF2424", fontSize: "50px", }}>
                About Us
            </h2>
            <div className='bg-patch-wall h-auto p-5'>
                <div className="relative container isolate overflow-x-clip layout-full before:backdrop-blur-xs prose-a:text-accent   from-primary-950/80 to-primary-700/90 bg-cover before:absolute before:-inset-[1px] before:bg-gradient-to-b dark:from-primary-950/70 dark:to-primary-950/90">
                    <div className="prose-invert relative  to-breakout">
                        <div className="flex flex-wrap items-center gap-8 lg:flex-nowrap">

                            <div className="flex-grow">
                                <br />
                                
                                  <p className='text-light '>We have what you need, whether it is custom embroidered patches, branded logos, or custom embroidered patches. Our experienced staff ensures that every detail of the logo, complex design, or embroidery patches for clothing is clean-cut and precise. We are recognized as the best place to buy patches, offering you a seamless experience from start to finish. If you want to create your own patch, our team's skilled craft skills can help your vision. We only utilize the highest-caliber threads and materials to create digital designs that are durable in addition to being vibrant and vivid. For businesses, our company logo patches are designed with precision and professionalism. Additionally, we provide a range of choices, including iron-on patches, which make it simpler than ever to incorporate your unique design into any article of clothing. Our clothing patches are strong and adaptable, making them ideal for customizing shirts, coats, and uniforms. We specialize in business patches due to our commitment to providing fast response times without compromising quality. Your embroidery looks fantastic, and we at US Digitize make it simple for you to achieve your goals.</p></div>
                                </div>
                            </div>
                        
                </div>
            </div>
            <h2 class="mb-2 mt-10 text-center  md:mt-16" style={{ color: "#FF2424", fontSize: "35px", }}>
            Our Story
                </h2>
                <br />

                <div class="items-center gap-12 md:flex md:flex-nowrap">

                    <div class="md:w-2/3 col-lg-8 text-center mx-auto w-75">
                        <p>
                        The journey started with the aim of helping the clients create what they want. Thus, after spending ten years in the industry of designing and digitizing, I directed my focus toward customer support in order to know exactly what people want. In this period, I acquired the ability to solve issues and worked out the particulars of every such project, making sure that every customer received quality service. Some time back, after years of altogether customer and digitizing, I resigned to broaden my horizons and launch US DIGITIZE. The objective was straightforward: provide digitizing services of specialized high-grade effectiveness and customer orientation. Today, having a motivated team, we have extended our services and are committed to continuing our growth by enabling individuals and businesses to create professional embroidery from their designs. As can be seen, our experience has always involved more expansion, education, and zeal. We are looking forward to continuing our endeavors of designing and digitalizing, all in the hopes of revolutionizing this industry
                          
                        </p>


                    </div>
               
                </div> 
                <div className='container'>

                {/* <div className='row d-flex align-items-center'>
                                    <div className='col-lg-6'>
                                        <img src='/about1.png' className='  style={{width: "50px"}}' /></div>
                                    <div className='col-lg-6' ></div>
                </div> */}

<section id="features" class="features">

      <div class="container" >

       
        
        <div class="row feature-icons" >
         

          <div class="row">

            <div class="col-xl-6 text-center" >
              <img src="/about1.png" class="img-fluid p-4" alt="" />
            </div>

            <div class="col-xl-6 d-flex content">
              <div class="row align-self-center gy-4">

                <div class="col-md-12 icon-box" >
                <img src='/about2.svg' className='' style={{width: "50px"}} />
                  <div>
                    <h4 className='fw-bold'>Quality in Every Patch</h4>
                    <p>At US Digitize, quality isn't just a promise, it's our practice. Our patches are designed to represent your identity and values with vibrant colors and durable materials, ensuring they stand out and last long.</p>
                  </div>
                </div>

                <div class="col-md-12 icon-box" >
                <img src='/about3.svg' className='' style={{width: "50px"}} />
                  <div>
                    <h4 className='fw-bold'>Customer Service Excellence
                    </h4>
                    <p>From personalized consultations to meticulous attention to your needs, we ensure a seamless experience from start to finish.</p>
                  </div>
                </div>

                <div class="col-md-12 icon-box" >
                  <img src='/about4.svg' className='' style={{width: "50px"}} />
                  <div>
                    <h4 className='fw-bold'>Guaranteed Satisfaction</h4>
                    <p>We stand firmly behind every patch we produce, Our goal is to not just meet your expectations but to exceed them, every single time.</p>
                  </div>
                </div>


              </div>
            </div>

          </div>

        </div>

      </div>

    </section>


                                </div>
                                <Footer />
        </div>
    )
}

export default Aboutus