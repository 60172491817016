import React from 'react'
import Head from './Head'
import Footer from './Footer'

const Service = () => {
    return (
        <div className=''>
            <Head />
            <h2 class="mb-2 mt-2 fs-1 text-center  " style={{ color: "#FF2424" }}>
                Services That We Provide Embroidery/Digitizing
            </h2> <br />
            <h2 class="mb-2 mt-2 text-center  fs-1" style={{ color: "#FF2424" }}>
                Embroidery/Digitizing Custom Patches <br />
            </h2> <br />
            <div className='container'>
                <div className='row d-flex align-items-center jusify-content-center mx-auto'>
                    <div className='col-lg-6'>
                        <img src='/service (2).png'  />
                    </div>
                    <div className='col-lg-6'> <br />
                        <p>We offer comprehensive and professional digitizing for embroidery services, whereby we take your images to make them into embroidery files. Our qualified digitizers have special techniques ensuring that every detail is precisely plotted where stitching is needed, creating excellent results without any rough edges. The embroidery digitizing procedure allows your design to be embroidered with bright threads as it is embellished on the fabric, starting from custom-embroidered patches to in-depth logos</p>
                    </div>
                    <h2 class="mb-2 mt-4 text-center  " style={{ color: "#FF2424", fontSize: "50px", }}>
                        Custom Patches <br />
                        </h2>  
                    <div className='col-lg-6'> <br />
                        <p>At US Digitize, we are into the business of manufacturing personalized patches. Whether the same is required for oneself, for an organization, or for conducting a special occasion, our patches are designed to meet the utmost precision and quality expectation. Be it embroidered patches for clothing or patches with a brand logo, there are limitless variations such as size, color, and style available, ensuring that the final patch is exactly what you envisioned.</p>     <br /> 
                    </div>
               
                    <div className='col-lg-6 d-flex justify-content-between'> 
                        <img src='/service (1).png' className='' />
                    </div>  <h2 class="mb-2 mt-4 text-center  " style={{ color: "#FF2424", fontSize: "50px", }}>
                    Vector Art<br /> 
                    </h2> <br />
                    <div className='col-lg-6'>
                        <img src='/service (3).png' className='img-fluid' />
                    </div>
                    <div className='col-lg-6'> <br />
                        <p>Our vector art services allow us to edit your designs and make them clean, scalable and high-resolution. Be it a logo, illustration, or custom design, our creative designers guarantee to provide high-quality, sharp, and precise vector files suitable for printing, embroidery, or on the web. There is no compromise when it comes to reproducing your image, and this is true however big or small the image is and in whatever medium.</p>
                    </div>
                </div >
            </div> <br />
            <Footer />
        </div>
    )
}

export default Service