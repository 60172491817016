import React from 'react'
import Head from './components/Head'
import Footer from './components/Footer'

const Blog = () => {


  const faqArray = [
    {
        question: "How long do iron-on patches last?",
        answer: "Iron-on patches can last for many years, but repeated washing may cause the adhesive to weaken, requiring reinforcement with stitches.",
    },
    {
      question: "Can I take off an iron-on patch after applying?",
      answer: "This is true, except that sometimes the residue or damage stays on the fabric. Use the heat of the iron or adhesives remover to loosen the patch, so you'll be left with some adhesive marks from your work.",
    },
    {
      question: "What kind of fabric is best for patching?",
      answer: "Patches will work best on thick materials such as denim, canvas, cotton, and even polyester. Any of the other varieties that are as fragile as silk or wool would be ravaged by the heat required to apply iron-on patches or the stitching used to apply sewing-on patches.",
    },
    {
      question: "How can I prevent my patch edges from peeling?",
      answer: "In the case of iron-on patches, enough temperature and pressure are needed so that the glue would bind the fabric in the right manner. If the edges of the patches begin to peel off, the reinforcement can easily be done with a few stitches or even fabric glue.",
    },
    {
      question: "Can I put patches on stretchy fabrics like leggings?",
      answer: "Yes, but you need to be aware that stretch fabrics tend to pill or flake away after time due to the patch. They work much better as a sew-on patch. But if it has to go on a stretch area of the garment, it should be applied in an area that won't put too much stress on the outside area.",
    }
  ];


  return (
    <div>
      <Head />

      <h2 className="mb-2 mt-2 text-center" style={{ color: "#FF2424", fontSize: "50px" }}>
        Blogs
      </h2>

      {/* <iframe
        loading="lazy"
        style={{
          position: "relative",
          width: "100%",
          height: "645vh",
          // maxHeight: "100vh",
          border: "none",
          borderRadius: "8px",
          padding: "0",
          margin: "0",
          overflow: "hidden"
        }}
        src="https://www.canva.com/design/DAGUsoGWybA/mnzMJ4zbUzVITgDux7BjlA/view?embed"
        allowFullScreen={true}
        allow="fullscreen"
      /> */}
    
      {/* <h2 className="mb-2 mt-2 text-center" style={{ color: "#FF2424", fontSize: "50px" }}>
        3 Easy Ways to Apply Patches
      </h2> */}

      {/* <div className='container'>
        <div className='row d-flex align-items-center jusify-content-center mx-auto'>
          <div className='col-lg-12'>
              <img src='/assets/blogs/1000064885.jpg'  />
          </div>

          <h2 className="mb-2 mt-2 text-center" style={{ color: "#FF2424", fontSize: "50px" }}>
            How to Apply Patches
          </h2>
          <div class="items-center gap-12 md:flex md:flex-nowrap">
              <div class="md:w-2/3 col-lg-8 text-center mx-auto w-75">
                  <p>
                  Once you have determined what kind of patch will work best in your favour, you will then
                  attach it to your fabric. Each of the methods has their advantages and disadvantages, and
                  your decision may depend on the material of your patch, the material that makes up the
                  fabric you are using, and how long you would want the patch to last                                      
                  </p>
              </div>          
          </div> <br/> 

          <h2 className="mb-2 mt-2 text-center" style={{ color: "#FF2424", fontSize: "40px" }}>
            3 Easy way to Apply Patches
          </h2> <br/>
          <div class="items-center gap-12 md:flex md:flex-nowrap">
              <div class="md:w-2/3 col-lg-8 text-center mx-auto w-75">
                  <p>
                    Before discussing the techniques of application, let's look at the types of patches. Each type
                    has its own specific use, specific application method, and requirements. We also use <a style={{ textDecoration: "underline"}} href='https://www.usdigitize.com/service' target='_blank'> custom patches </a>
                  </p> <br/>

                  <ol>
                    <li>
                      <p>
                        <b style={{ fontSize: "20px" }}>1. Iron-On Patches</b>: <br/>
                        The most common type is an iron-on patch that has a heat-activated adhesive backing, thus
                        allowing its use upon placing it on using a household iron. However, they are great for
                        ornamentation. You can be able to add your personality to it. However, they are not very
                        great for heavy-use fabrics; the adhesive may deteriorate over time.
                      </p>
                    </li>
                    <li> <br/>
                      <p>
                        <b style={{ fontSize: "20px" }}>2. Sew-On Patches</b>: <br/>
                        Sew-on patches: These you will sew onto your fabric, either by hand or with a sewing
                        machine. The sew-on patch provides the tightest, most permanent attachment and is best
                        suited for heavy-duty applications, like backpacks, work uniforms, or parts of the body that
                        are subjected to heavy use.
                      </p>
                    </li>
                    <li> <br/>
                      <p>
                        <b style={{ fontSize: "20px" }}>3. Velcro Patches</b>: <br/>
                        Velcro patches are preferably used for uniforms or any other situations where a patch needs
                        to be put on and taken off frequently, removed, and replaced often. The Velcro shall remain
                        as the hook side, and the loop side is either sewn or adhered to the fabric. This type of patch
                        is especially popular in military or tactical gear.
                      </p>
                    </li>
                  </ol>  
              </div>
              
          </div> <br/> 


          <h2 class="mb-2 mt-4 text-center  " style={{ color: "#FF2424", fontSize: "50px", }}>
            1. Applying Iron-On Patches <br />
          </h2>  
          <div className='col-lg-6 d-flex justify-content-between'> 
              <img src='/service (1).png' className='' />
          </div>
          <div className='col-lg-12'> <br />
              <p>Iron-on patches are perfect for new patchers as, with a minimum amount of application
                practice, they are quite simple to adhere.</p> <br /> 
                <ol>
                  <li>
                    <p>
                      <b>1. Prepare the Area</b>: <br/> <br/>
                      Choose the location where you want your patch. Ensure that your fabric is clean and free
                      from any moisture or dirt, as these may interfere with the adhesive bonding. You should iron
                      the section before this as well to iron out the wrinkles.
                    </p>
                  </li>
                </ol>  
              <br /> 
          </div>

          <h2 class="mb-2 mt-4 text-center  " style={{ color: "#FF2424", fontSize: "50px", }}>
            Custom Patches <br />
          </h2>  
          <div className='col-lg-6'> <br />
              <p>At US Digitize, we are into the business of manufacturing personalized patches. Whether the same is required for oneself, for an organization, or for conducting a special occasion, our patches are designed to meet the utmost precision and quality expectation. Be it embroidered patches for clothing or patches with a brand logo, there are limitless variations such as size, color, and style available, ensuring that the final patch is exactly what you envisioned.</p>     <br /> 
          </div>
          <div className='col-lg-6 d-flex justify-content-between'> 
              <img src='/service (1).png' className='' />
          </div>

        </div>
      </div> */}

      {/* FAQs */}
      {/* <main
          id="content"
          className="prose prose-primary max-w-full dark:prose-invert container"
      >
          <div className="relative isolate  overflow-x-clip layout-full before:backdrop-blur-xs prose-a:text-accent dark:bg-header-dark">
              <div className="prose-invert relative  md:pb-4 md:pt-24 prose-h1:text-center">
                  <h1 className="text-center">Frequently Asked Questions</h1>
              </div>
          </div>
          <div className="grid gap-8 py-12 lg:grid-cols-2">
              {faqArray.map((faq, index) => (
                  <div key={index} className="mb-12 flex items-start justify-start">
                      <svg
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          className="mr-4 size-8 flex-none text-accent"
                          aria-hidden="true"
                      >
                          <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                      </svg>
                      <div>
                          <h2 className="mb-3 mt-0 fw-bold text-dark">{faq.question}</h2>
                          <p>{faq.answer}</p>
                      </div>
                  </div>
              ))}
          </div>
      </main>      */}

      <Footer />
    </div>
  )
}

export default Blog
