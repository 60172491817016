import React from 'react'
import Header from './components/Haeder'
import Footer from './components/Footer'
import Head from './components/Head'


const Landingpage = () => {
    return (
        <div>
            <Header />
            {/* <Banner /> */}

            <main id="content" className="prose container prose-primary max-w-full layout-grid dark:prose-invert  prose prose-primary max-w-full layout-grid dark:prose-invert">
                <h2 class="mb-2 mt-10 text-center  md:mt-16" style={{ color: "#FF2424", fontSize: "35px", }}>
                    Your All-In-One Source for Customized Patches
                </h2>
                <br />

                <div class="items-center gap-12 md:flex md:flex-nowrap">

                    <div class="md:w-2/3 col-lg-8 text-center mx-auto w-75">
                        <p>
                            US Digitize welcomes you to a world that offers more than just patchmaking. We are your one-stop destination for adornments and the foremost manufacturers of quality fashion patches and customized embroidered and printed patches in the whole region. We are the place to go if you need clothing patches for business, sports, law enforcement, the military, motorcycle events, or any other activity. We can help if you are trying to find a certain garment patch.

                        </p>


                    </div>
                </div> <br /><br />
                <img class="mx-auto mask mask-size-contain w-75"
                    src="/patch.png" loading="lazy" width="1300" height="372"
                    alt="A row of six embroidered patches, each representing different fictional motifs and organizations including Ruby The Hatchet, The Walking Dead,Force Security, AIM-9M, Chabelly, Fire Dept., and U.S. Forest Service"
                    title="Exclusive custom patch design creations for brands like Ruby The Hatchet, The Walking Dead, Force Security, AIM-9M, Chabelly, Fire Dept., and the U.S. Forest Service" />

                <h2 class="clear-both text-center" style={{ color: "#FF2424" }}>We Make Custom Patches for Every Purpose</h2>
                <p class="clear-both text-center w-75 mx-auto" >
                    Our custom-embroidered patches are adaptable and meant for multiple purposes. Whether it's for team building or enhancing your business through advertising, our patches offer these and many more. At US Digitize, you can create custom patches tailored to every event, and every design speaks your mind. When you’re ready, you can buy custom patches that reflect your unique vision
                </p>
                <div className='container'>
                    <ul class="grid list-none grid-cols-2 gap-4 pl-0 md:grid-cols-2 mx-auto">
                        <li>
                            <div class="-mb-6 flex items-center gap-6">
                                <img src="/police-uniform 1.png" width="361" height="338" alt="" class="size-16"
                                    loading="lazy" />
                                <h3 class="my-0">Uniforms and Apparel</h3>
                            </div>
                            <p>
                                Uniforms of any kind (sports, martial arts, work-related, and even functional or decorative) are perfectly suited for the application of embroidered patches for jackets.
                            </p>
                        </li>
                        <li>
                            <div class="-mb-6 flex items-center gap-6">
                                <img src="/briefcase 1.png" width="356" height="315" alt="" class="size-16"
                                    loading="lazy" />
                                <h3 class="my-0">Businesses and Nonprofits</h3>
                            </div>
                            <p>
                                Designed emblems and logos are essential for corporate and charity organizations for branding purposes. Our company logo patches can be attached to workers’ gear, handed out during functions as souvenirs, or marketed to support the organization.
                            </p>
                        </li>
                        <li>
                            <div class="-mb-6 flex items-center gap-6">
                                <img src="/jacket (1) 1.png" width="371" height="299" alt="" class="size-16"
                                    loading="lazy" />
                                <h3 class="my-0">Personal Use</h3>
                            </div>

                            <p>
                                Our patches are not only for organizations but also for individuals. Wear items such as hats, purses, or jackets with embroidered patches to personalize them and show off your interests. passions, and likes.
                            </p>
                        </li>

                        <li>
                            <div class="-mb-6 flex items-center gap-6">
                                <img src="/calendar 1.png" width="353" height="369" alt="" class="size-16"
                                    loading="lazy" />
                                <h3 class="my-0">Events and Commemorations</h3>
                            </div>

                            <p>
                                Whether it’s for a first scouts’ camping trip, a business achievement, or an annual motorcycle club gathering, we offer the best custom patches to commemorate the moment. With US Digitize, you can also buy patches at competitive rates, including cheap patches, without compromising on quality
                            </p>
                        </li>
                    </ul>
                </div>
                <div className='container text-center'>
                    <h2 class="text-center">Why Choose us</h2>
                    <h2 >Excellent craftsmanship</h2>
                    <p className='text-center w-75 mx-auto'>Our patches are made from superior materials, ensuring vibrant colors and durable designs.  Our attention to detail ensures every custom patch reflects your exact vision.</p>
                    <h2 >Customization to Suit Your Needs</h2>
                    <p className='text-center w-75 mx-auto'>From personal fashion patches to company logo patches, we offer full customization. You can choose every detail, from size to color, for a truly unique patch.</p>
                    <h2 >Fast and Reliable Service</h2>
                    <p className='text-center w-75 mx-auto'>To make sure that you have your patches when you need them, we promise quick shipping without compromising quality. Your deadlines will always be met thanks to our effective service.</p>
                    <h2 >Affordable Pricing</h2>
                    <p className='text-center w-75 mx-auto'>We provide cheap patches without sacrificing quality at competitive prices. We offer affordable solutions for all orders, no matter how big or small.</p>
                    <h2 >Expertise and Experience</h2>
                    <p className='text-center w-75 mx-auto'>With over 10 years in the digitizing industry, we know how to create standout patches. From simple sew on patches for clothes to intricate designs, we deliver with expertise.</p>
                    <h2 >Customer-Centric Approach</h2>
                    <p>Our primary goal is your pleasure, and we provide individualized assistance all along the way. We are here to assist you in creating custom patches that precisely match your needs.</p>
                    {/* <ul class="relative list-none pl-0">
                        <li class="gap-8 md:flex">
                            <img class="mx-auto self-center   " loading="lazy" width="300" height="300"
                                src="/quality2.png"
                                alt="PVC patch in the shape of a price tag with the text Best Price in bold white letters, featuring a teal and orange color scheme"
                                title="PVC Patch with Best Price Design in Teal and Orange" />
                            <div>
                                <h3>Competitive Prices</h3>
                                <p>
                                At UsDigitize.com, we are committed to providing top-notch custom patches without straining your budget. Our expertise is dedicated solely to crafting patches that meet your group’s design specifications.
With over twenty years of experience and having produced millions of custom patches, we have the expertise and capability to cater to a wide array of clients. Our clientele includes Scout groups, camping enthusiasts, uniformed professionals such as firefighters, police officers, EMTs, prestigious military units, and Fortune 500 companies.
We take great pride in our work and the value we offer. We are privileged to have created patches for every United States Armed Forces branch.
When you choose UsDigitize.com, you can be assured that you are receiving the highest-quality patches at the most competitive prices. Your satisfaction is our top priority, as is our dedication to providing you with an exceptional patch-making experience.
                                   
                                </p>


                            </div>
                        </li>

                        <li class="gap-8 md:flex">
                            <img class="mx-auto self-center rounded-lg " loading="lazy" width="300" height="300"
                                src="/quality1.png"
                                alt="A design transformation image showing a sketch transitioning into a full-color patch. The top sketch features characters from 'Avengers: Age of Ultron' with Captain America in the forefront. Below, the colored pin reveals the characters in their iconic red, white, and blue costumes with the 'AVENGERS' logo in bold red letters."
                                title="Patches4less offers a simple design process with free revisions" />
                            <div>
                                <h3>Simple Design Process</h3>
                                <p>
                                Creating a patch for your team, organization, or company should be an exciting adventure, not a stressful task. At USDigitize.com, we’ve streamlined the design process to ensure it’s as easy and enjoyable as possible.
Our talented graphic artists are among the best in the industry, ready to turn your ideas into reality. Simply share a picture of your logo, an existing patch, or any concept you want transformed into a custom embroidered patch.
We accept design images in almost any format, making it incredibly convenient for you. You can even email us a sketch or drawing of your envisioned design. Our goal is to adapt to your needs and bring your design ideas to life.
                                </p>

                               
                            </div>
                        </li>

                        <li class="gap-8 md:flex">
                            <img class="mx-auto self-center rounded-lg " loading="lazy" width="300" height="300"
                                src="/quality.png"
                                alt="A stack of embroidered patches with various designs, including one with the text 'GHOST,' another with 'RAVEN'. The patches feature bold text and vibrant colors, showcasing a mix of modern and retro themes."
                                title="Customize your patches to your hearts desire with our many patch options" />
                            <div>
                                <h3>Plenty of Customization Options</h3>
                                <p>
                                At US Digitize, we understand that variety adds zest to life. That’s why we offer an extensive range of patch options to cater to your unique needs. Our aim is to provide you with a fully personalized product that perfectly captures your vision.
We offer a broad selection of sizes and custom shapes, ensuring your patches fit seamlessly wherever you plan to display them. But our customization options don’t end there. You can choose from a variety of border types to frame your design and a wide spectrum of thread colors to bring it to vibrant life.
Additionally, we provide several attachment methods, allowing you to select the one that best suits your application needs.
Simply tell us what you envision, and our dedicated team of artists will bring it to life. With US Digitize, creating your dream patch is straightforward and enjoyable. Let us show you how easy and fun the process of ordering custom patches can be!
                                </p>

                              
                            </div>
                        </li>
                    </ul> */}
                </div> <br />

            </main>
            <div className=''>
                <aside
                    class="bg-primary-600 bg-header bg-cover bg-center border-b-4 border-t-4 border-primary-800 py-8 layout-full">
                    <div class="prose container prose-invert max-w-full drop-shadow-md">
                        <div class="flex flex-wrap items-center gap-8 sm:flex-nowrap">
                            <div>
                                <h2 class="mt-0 text-center text-4xl text-light">
                                    Every Custom Patch We Make is Backed by Our Outstanding Customer Service and 100% Quality Guarantee
                                </h2>
                                <p class="lead text-pretty text-center text-neutral-200">
                                    If you have questions, feel free to contact us via our
                                    <a href="/contact">contact form</a>
                                    ,
                                    <br />
                                    email us at
                                    <a href="//cdn-cgi/l/email-protection#9eedfff2fbeddeeeffeafdf6fbedaaf2fbededb0fdf1f3"
                                        class="font-heading font-black text-white"><span class="__cf_email__"
                                            data-cfemail="582b39343d2b1828392c3b303d2b6c343d2b2b763b3735">digitize@usdigitize.com</span></a>

                                    , or call us toll-free at
                                    <a
                                        class="whitespace-nowrap font-heading font-black text-white">
                                        +1 516-927-7625
                                    </a>
                                </p>
                            </div>

                            <img class="not-prose mx-auto" loading="lazy" src="/lowprice.png"
                                width="300" height="300" alt="" aria-hidden="true"
                                title="At Patches4less.com we always guarantee the lowest price" />
                        </div>
                    </div>
                </aside>
                <Footer />
            </div>
        </div>
    )
}

export default Landingpage